<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">{{ name }} - Device Availability</h3>
		</div>
		<div class="card-body pt-2">
			<ITCSpinner :loaded="loaded">
				<div v-if="data && !error">
					<div class="row">
						<div class="col-md-6">
							<div class="row bg-secondary" style="padding: 15px 0px">
								<div class="col-md-6">Today</div>
								<div class="col-md-6 text-left">{{ data.today }}%</div>
							</div>
							<div class="row bg-grey" style="padding: 15px 0px">
								<div class="col-md-6">Yesterday</div>
								<div class="col-md-6 text-left">{{ data.yesterday }}%</div>
							</div>
							<div class="row bg-secondary" style="padding: 15px 0px">
								<div class="col-md-6">Last 7 Days</div>
								<div class="col-md-6 text-left">{{ data.last7 }}%</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="row bg-grey" style="padding: 15px 0px">
								<div class="col-md-6">This Month</div>
								<div class="col-md-6 text-left">{{ data.thismonth }}%</div>
							</div>
							<div class="row bg-secondary" style="padding: 15px 0px">
								<div class="col-md-6">Last 30 Days</div>
								<div class="col-md-6 text-left">{{ data.last30 }}%</div>
							</div>
							<div class="row bg-grey" style="padding: 15px 0px">
								<div class="col-md-6">Last Month</div>
								<div class="col-md-6 text-left">{{ data.lastmonth }}%</div>
							</div>
						</div>
					</div>
				</div>
				<span class="fade-element-in" v-if="error">
					<i class="fa fa-exclamation-circle font-red" aria-hidden="true"></i> This site has no data
					available for the selected range of time.
				</span>
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SolarwindsAvailability',
	props: ['name', 'id'],
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	data() {
		return {
			loaded: false,
			data: {},
			error: false,
		};
	},
	created() {
		this.loaded = false;
		this.$http
			.get(`solarwindsavail/${this.id}`)
			.then((resp) => {
				this.data = resp.data.data;
				if (!this.data) {
					this.error == true;
				}
				if (Object.keys(this.data).length != 8) {
					this.error = true;
				}

				this.loaded = true;
			})
			.catch((e) => {
				this.error = true;
			});
	},
};
</script>

<style>
</style>